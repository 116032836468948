import { useEffect,useState } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
function Success() {

  const [transactionDetails, setTransactionDetails] = useState(null);

  const location = useLocation();

  // Use URLSearchParams to parse the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Extract parameters
  const transactionId = queryParams.get('transaction_id');
  const errCode = queryParams.get('err_code');
  const orderDate = queryParams.get('order_date');
  const responseKey = queryParams.get('Response_Key');
  const validationHash = queryParams.get('validation_hash');
  const transactionAmount = queryParams.get('transaction_amount');
  const transactionCurrency = queryParams.get('transaction_currency');
  const paymentType = queryParams.get('PaymentType');
  const paymentName = queryParams.get('PaymentName');
  const recurringTxn = queryParams.get('Recurring_txn');
  const emailAddress = queryParams.get('email_address');
  const mobileNo = queryParams.get('mobile_no');

  // Retrieve and parse the transaction details from local storage
  const storedDetails = localStorage.getItem('transactionDetails');
  console.log(storedDetails)
  useEffect(() => {
    if (storedDetails) {
      let data = JSON.parse(storedDetails)
      setTransactionDetails(data);
    }
    
  } ,[storedDetails]);

  console.log(transactionDetails)
       
     
    //  call api of voucher bought data save and subscription bought data save
    // base on type value
   console.log({transactionId,...transactionDetails})
    useEffect(() => {
      // Define an async function to handle the API call
      const fetchData = async () => {
          if (transactionDetails) {
              console.log(transactionDetails); // Log to verify the data

              try {
                  if (transactionDetails.type == 'Voucher') {
                      // API call for voucher
                   let data = {transactionId,...transactionDetails}
                   if (data.transactionId){
                      const response = await axios.post('https://olx.ashhkaro.com/common/voucherBought/createVoucherPurchases', 
                          // Your data here
                          data
                      );
                      console.log('Voucher purchase success:', response.data);
                    } else{
                      console.log('Voucher purchase failed:', "Transaction ID not found");
                    }
                      // Handle further logic if needed
                  } else if (transactionDetails.type == 'Subscription') {
                      // API call for subscription
                     
                      let data = {transactionId,...transactionDetails}
                    if (data.transactionId){
                      const response = await axios.post('https://olx.ashhkaro.com/common/addSubscriptionBought/createAdsSubscriptions', data);
                      console.log('Subscription purchase success:', response.data);
                      let id = data.businessId
                      let subscriptionId = data.addId
                      const responses = await axios.patch(`https://olx.ashhkaro.com/common/business/businesses/${id}/subscription`,{subscriptionId} );
                      console.log(responses)
                    }
                    else{
                      console.log('Subscription purchase failed:', "Transaction ID not found");
                    }
                      // Handle further logic if needed
                  }
              } catch (error) {
                  console.error('API call error:', error);
                  // Handle error appropriately
              }
          }
      };

      fetchData(); // Execute the fetchData function
  }, [transactionDetails,transactionId]); // Depend on transactionDetails to re-run this effect


  return (
    <div className="App">
         <h1>

             Congratulations! Payment Successfull

         </h1>
   </div>
  )
}

export default  Success